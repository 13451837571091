
    /* ----------------------------------------
            Imports
        ---------------------------------------- */

    import { Component, Vue } from 'vue-property-decorator';
    import NotFound from '@view/errors/404.vue';

    /* ----------------------------------------
            Component
        ---------------------------------------- */

    @Component({
        props: ['NotFound']
    })

    /* ----------------------------------------
            Export
        ---------------------------------------- */
    export default class Error404View extends Vue {}
